import { configureStore } from "@reduxjs/toolkit";
import { render, renderHook } from "@testing-library/react";
import React, { ReactElement, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Provider } from "react-redux";
import { MemoryRouter } from "react-router-dom";
import { StyleSheetManager } from "styled-components";
import { apiReducers, middlewares, sliceReducers } from "../store";
import { shouldForwardProp } from "../utilities/domUtils";

const setupStore = (preloadedState = {}) =>
  configureStore({
    reducer: { ...sliceReducers, ...apiReducers },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    preloadedState,
  });

export const StyledWrapper = ({ children }) => <StyleSheetManager shouldForwardProp={shouldForwardProp}>{children}</StyleSheetManager>;

export function renderWithStore(ui, state) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <Provider store={store}>{children}</Provider>
      </StyledWrapper>
    );
  }
  return { store, ...render(ui, { wrapper }) };
}

export function renderWithContext(ui, Context, value) {
  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <Context.Provider value={value}>{children}</Context.Provider>
      </StyledWrapper>
    );
  }
  return render(ui, { wrapper });
}

export function renderWithStoreAndContext(ui, state, Context, contextValue) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <Provider store={store}>
          <Context.Provider value={contextValue}>{children}</Context.Provider>
        </Provider>
      </StyledWrapper>
    );
  }
  return { store, ...render(ui, { wrapper }) };
}

export const renderWithStoreAndContextAndFormProvider = (ui: ReactElement, state, Context, contextValue, formValues?: {}) => {
  const store = setupStore(state);

  const Wrapper = ({ children }: { children: ReactNode }) => {
    const methods = useForm<Record<string, unknown>>({ defaultValues: formValues, mode: "onChange" });

    return (
      <StyledWrapper>
        <FormProvider {...methods}>
          <Provider store={store}>
            <Context.Provider value={contextValue}>{children}</Context.Provider>
          </Provider>
        </FormProvider>
      </StyledWrapper>
    );
  };

  return {
    ...render(ui, { wrapper: Wrapper }),
  };
};

export function renderHookWithStore(hook, state) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <Provider store={store}>{children}</Provider>
      </StyledWrapper>
    );
  }
  return renderHook(hook, { wrapper });
}

export function renderHookWithStoreAndContext(hook, state, Context, contextValue) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <Provider store={store}>
          <Context.Provider value={contextValue}>{children}</Context.Provider>
        </Provider>
      </StyledWrapper>
    );
  }
  return renderHook(hook, { wrapper });
}

export function renderWithStoreAndLocation(pathname, ui, state) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <MemoryRouter initialEntries={[pathname]}>
          <Provider store={store}>{children}</Provider>
        </MemoryRouter>
      </StyledWrapper>
    );
  }
  return { store, ...render(ui, { wrapper }) };
}

export function renderHookWithStoreAndLocation(pathname, hook, state) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <MemoryRouter initialEntries={[pathname]}>
          <Provider store={store}>{children}</Provider>
        </MemoryRouter>
      </StyledWrapper>
    );
  }
  return renderHook(hook, { wrapper });
}

export function renderWithStoreAndContextAndLocation(pathname, ui, state, Context, contextValue) {
  const store = setupStore(state);

  function wrapper({ children }) {
    return (
      <StyledWrapper>
        <MemoryRouter initialEntries={[pathname]}>
          <Provider store={store}>
            <Context.Provider value={contextValue}>{children}</Context.Provider>
          </Provider>
        </MemoryRouter>
      </StyledWrapper>
    );
  }
  return { store, ...render(ui, { wrapper }) };
}
