import { Box, Breakpoints, ButtonText, Text, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NoPageWrapper } from "./NotFoundPage.styles";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useHasMaxWidth(Breakpoints.XS);

  const onGoBack = () => {
    navigate("/");
  };

  return (
    <NoPageWrapper>
      <Box pv="M" ph="S">
        <Title color="accent">{t("notFoundPage.title")}</Title>
        <Text>{t("notFoundPage.message")}</Text>
        <Box mt="S">
          <ButtonText icon="BackNavigation" iconSize={isMobile ? "L" : "S"} onClick={onGoBack}>
            {t("Common.back")}
          </ButtonText>
        </Box>
      </Box>
    </NoPageWrapper>
  );
};
