import styled, { css } from "styled-components";

import { Palette, Spacing } from "@secuis/ccp-react-components";

export const SectionComponent = styled.div`
  background-color: ${Palette.Navy750};
  padding: ${Spacing.M}px;
  border-radius: ${Spacing.XXS}px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 216px;
  width: 286px;
`;
export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;
export const CreatorSection = styled.div`
  display: flex;
  gap: ${Spacing.S}px;
`;

export const SectionButton = styled.div`
  margin-top: ${Spacing.S}px;
`;
export const SectionPreview = styled.div<{ disabled?: boolean }>`
  text-decoration: underline ${Palette.Purple300};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      text-decoration: underline ${Palette.Navy400};
    `}
`;

export const BadgeSection = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}
`;
export const SectionsEditorWrapper = styled.div`
  margin-top: ${Spacing.L}px;
`;
export const ToggleReadyWrapper = styled.div`
  display: flex;
  margin-top: ${Spacing.M}px;
  align-items: center;
  gap: ${Spacing.S}px;
`;

export const SectionsListComponent = styled.div<{ savedReport: string }>`
  ${(props) =>
    !props.savedReport &&
    css`
      margin-top: ${Spacing.L}px;
    `}

  padding-bottom: ${Spacing.L}px;
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.M}px;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const ButtonWrapperCancel = styled.div`
  margin-right: ${Spacing.S}px;
`;

export const WrappedRefreshButton = styled.div`
  margin: ${Spacing.M}px 0;
`;

export const SectionActionsWrapper = styled.div``;

export const SectionNameWrapper = styled.div`
  display: flex;
  gap: ${Spacing.XS}px;
`;
export const ApproveSectionWrapper = styled.div`
  margin-top: ${Spacing.XL}px;

  button {
    width: auto;
  }
`;

export const SectionDetailsWrapper = styled.div`
  background-color: ${Palette.Navy750};

  padding: ${Spacing.M}px;
  border-radius: ${Spacing.XXS}px;
  margin: ${Spacing.M}px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
