import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorModel } from "../../models/ErrorModel";

interface AppState {
  errors: ErrorModel[];
}

const initialState: AppState = {
  errors: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<ErrorModel>) => {
      state.errors.push(action.payload);
    },
    clearErrors: (state) => {
      state.errors = [];
    },
  },
});

export const appActions = appSlice.actions;
