import { Button, Stack, ToasterContext, Tooltip } from "@secuis/ccp-react-components";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PdfIcon from "../../../../assets/icons/PdfIcon";
import { downloadFile } from "../../../../utilities/fileUtils";
import { getDataFromBE, updateReport } from "../../../../utilities/userUtils";
import { DeleteAttachment } from "../FileUpload/DeleteAttachment";
import { ButtonsWrapper, FileName, UploadedFileSections, Wrapper } from "./UploadFileComponentSection.styles";

interface UploadFileComponentSectionsProps {
  reportId: string;
  userToken: string;
  attachmentId?: string;
  setIsApproved: (value: boolean) => void;
  isDisabled: boolean;
}

export const UploadFileComponentSections: FC<UploadFileComponentSectionsProps> = ({ reportId, userToken, attachmentId, setIsApproved, isDisabled }) => {
  const fileInputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { addToast } = useContext(ToasterContext);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [savedId, setSavedId] = useState(attachmentId);

  useEffect(() => {
    setSavedId(attachmentId);
    const fetchFileTitle = async () => {
      try {
        const result = await getDataFromBE(userToken, `/reports/${reportId}/attachments/${attachmentId}`);
        setFileName(result?.file_name);
      } catch (e) {
        addToast({ title: t("settings.notification.failed"), type: "high", icon: "Error" });
      }
    };

    if (attachmentId) {
      fetchFileTitle();
    }
  }, [attachmentId]);

  const handleAttachmentChangeLocal = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        setFile(file);
        const reader = new FileReader();

        reader.onload = async (event) => {
          const base64String = (event.target.result as string).split(",")[1];

          const content = {
            file: base64String,
            fileName: file.name,
            content: "",
            language: i18n.language,
          };
          try {
            if (!savedId) {
              setFileName("");
              const uploadResult = await updateReport(userToken, `/reports/${reportId}/attachments`, content, "POST");

              setSavedId(uploadResult?.id);
              addToast({ title: t("Notification.attachmentAdded"), type: "success", icon: "Success" });
            } else {
              setFileName("");
              const uploadResult = await updateReport(userToken, `/reports/${reportId}/attachments/${savedId}`, content, "PUT");

              setSavedId(uploadResult?.id);
              addToast({ title: t("Notification.attachmentUpdated"), type: "success", icon: "Success" });
            }

            setFileName(file?.name || "uploaded.pdf");
            setIsApproved(false);
          } catch (e) {
            addToast({ title: t("settings.notification.failed"), type: "high", icon: "Error" });
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      addToast({ title: t("settings.notification.failed"), type: "high", icon: "Error" });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const convertAndDownload = async () => {
    if (file || savedId) {
      try {
        const result = await getDataFromBE(userToken, `/reports/${reportId}/attachments/${savedId}`);
        downloadFile(result);
      } catch (e) {
        addToast({ title: t("settings.notification.failed"), type: "high", icon: "Error" });
      }
    }
  };

  const resetFile = () => {
    setSavedId(null);
    setFile(null);
    setIsApproved(false);
    fileInputRef.current.value = "";
  };

  return (
    !isDisabled && (
      <Wrapper data-testid="FileUpload">
        <input
          role="search"
          type="file"
          id="pdf-file-input"
          data-testid="pdf-file-input"
          onChange={handleAttachmentChangeLocal}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        {!(file || savedId) && (
          <ButtonsWrapper>
            {reportId && !file && !attachmentId ? (
              <Button data-testid="UploadFileButton" mode="contained" color="accent" onClick={handleButtonClick}>
                {t("Section.attachFile")}
              </Button>
            ) : (
              <Tooltip message={!reportId ? t("CreateReport.tooltipSaveReport") : t("CreateReport.tooltipOnlyOneFile")} place="top" color="purple">
                <Button data-testid="UploadFileButton" mode="contained" color="accent" disabled={!reportId || !!savedId} onClick={handleButtonClick}>
                  {t("Section.attachFile")}
                </Button>
              </Tooltip>
            )}
          </ButtonsWrapper>
        )}
        {(file || savedId) && (
          <UploadedFileSections>
            <Stack alignItems="center" gap="XS">
              <PdfIcon />
              <FileName data-testid="download-file" onClick={convertAndDownload}>
                {fileName}
              </FileName>
            </Stack>
            <DeleteAttachment reportId={reportId} attachmentId={savedId} resetFile={resetFile} />
          </UploadedFileSections>
        )}
      </Wrapper>
    )
  );
};
