import { ButtonText, Stack, ToasterContext } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteAttachmentMutation } from "../../../../store/reports/ReportsApi";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";

export const DeleteAttachment = ({ reportId, attachmentId, resetFile }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToasterContext);
  const [isOpen, setIsOpen] = useState(false);

  const [deleteAttachment] = useDeleteAttachmentMutation();

  const performDelete = () => {
    deleteAttachment({ reportId, attachmentId })
      .unwrap()
      .then(() => {
        resetFile();
        addToast({ title: t("Reports.deleteAttachmentNotification"), type: "success", icon: "Success" });
      })
      .catch(() => true);
  };
  return (
    <Stack justifyContent="flex-end">
      <ButtonText icon="Delete" onClick={() => setIsOpen(true)} />
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={performDelete} title={t("Reports.deleteAttachment")} />
    </Stack>
  );
};
