import { GlobalStyles, LoadingIndicator, Toaster } from "@secuis/ccp-react-components";
import React, { Suspense, useRef } from "react";
import { withStore } from "react-context-hook";
import { RouterProvider } from "react-router-dom";
import { StyleSheetManager } from "styled-components";
import { getRouter } from "./Router";
import { i18nextInit } from "./config/i18next";
import { syncI18nCultures } from "./config/i18nextSync";
import AuthGate from "./context/AuthGate";
import { useRoles } from "./hooks/useRoles";
import { SpecificStyles } from "./styles/SpecificStyles";
import { shouldForwardProp } from "./utilities/domUtils";

i18nextInit();
syncI18nCultures();

const App: React.FC = () => {
  const scrollRef = useRef(null);
  const { couldCreateReport, canSeeBriefs } = useRoles();
  const router = getRouter({ couldCreateReport, canSeeBriefs, scrollRef });

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <SpecificStyles />
      <GlobalStyles />
      <Suspense fallback={<LoadingIndicator size="L" />}>
        <Toaster>
          <AuthGate>
            <RouterProvider router={router} />
          </AuthGate>
        </Toaster>
      </Suspense>
    </StyleSheetManager>
  );
};

export default withStore(App);
