import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, Report } from "../../models/ReportModel";
import { draftsApi } from "./DraftsApi";

export type DraftsState = {
  data: Report[];
  status: FetchStatus;
};

const initialState: DraftsState = {
  data: [],
  status: "idle",
};

export const draftsSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(draftsApi.endpoints.fetchDrafts.matchPending, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(draftsApi.endpoints.fetchDrafts.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(draftsApi.endpoints.fetchDrafts.matchFulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = action.meta.requestStatus;
      });
  },
});
