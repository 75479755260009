import { createApi } from "@reduxjs/toolkit/query/react";
import { Report } from "../../models/ReportModel";
import { Section } from "../../models/SectionModel";
import { baseQuery } from "../../utilities/baseQuery";

export interface FetchItemsParams {
  url: string;
  page?: number;
  limit?: number;
  lang: string;
}

export interface FetchItemsMetaResponse {
  meta: {
    count: number;
    limit: number;
    page: number;
  };
}

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Report"],
  endpoints: (builder) => ({
    getReports: builder.query<{ data: Report[] } & FetchItemsMetaResponse, FetchItemsParams>({
      query: ({ url, lang, page }) => ({
        url: `/reports?${url}&limit=20&lang=${lang}&page=${page}`,
      }),
      providesTags: ["Report"],
    }),
    deleteReport: builder.mutation<void, { reportId: string }>({
      query: ({ reportId }) => ({
        url: `/reports/${reportId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Report"],
    }),
    createReport: builder.mutation<Report, Partial<Report>>({
      query: (item) => ({
        url: "reports",
        method: "POST",
        body: item,
      }),
    }),
    createSection: builder.mutation<Section, { item: Partial<Section>; reportId: string }>({
      query: ({ item, reportId }) => ({
        url: `/reports/${reportId}/sections`,
        method: "POST",
        body: item,
      }),
    }),
    deleteAttachment: builder.mutation<void, { reportId: string; attachmentId: string }>({
      query: ({ reportId, attachmentId }) => ({
        url: `/reports/${reportId}/attachments/${attachmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Report"],
    }),
    fetchSections: builder.query<Report, { reportId: string }>({
      query: ({ reportId }) => ({
        url: `/reports/${reportId}/sections`,
      }),
      providesTags: ["Report"],
    }),
    updateSection: builder.mutation<Section, { reportId: string; sectionId: string; item: Partial<Section> }>({
      query: ({ reportId, sectionId, item }) => ({
        url: `/reports/${reportId}/sections/${sectionId}`,
        method: "PATCH",
        body: item,
      }),
    }),
    updateReport: builder.mutation<Report, { reportId: string; item: Partial<Report> }>({
      query: ({ reportId, item }) => ({
        url: `/reports/${reportId}`,
        method: "PATCH",
        body: item,
      }),
    }),
    shareReport: builder.mutation<void, { report_id: string; emails: string[]; language: string }>({
      query: ({ report_id, emails, language }) => ({
        url: "/send-report",
        method: "POST",
        body: { report_id, emails, language },
      }),
    }),
  }),
});

export const {
  useCreateReportMutation,
  useGetReportsQuery,
  useLazyGetReportsQuery,
  useDeleteReportMutation,
  useDeleteAttachmentMutation,
  useLazyFetchSectionsQuery,
  useCreateSectionMutation,
  useShareReportMutation,
  useUpdateReportMutation,
  useUpdateSectionMutation,
} = reportsApi;
