import { Button, ToasterContext } from "@secuis/ccp-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRoles } from "../../../../hooks/useRoles";
import { useUserData } from "../../../../hooks/useUserData";
import { useUpdateSectionMutation } from "../../../../store/reports/ReportsApi";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { ApproveSectionWrapper } from "./styled";

const ApproveSection = ({ isChanged, isApproved, isReady, elemSectionId, savedReport, setApprover, setIsApproved }) => {
  const { t } = useTranslation();
  const { userId } = useUserData();
  const { userData } = useLoggedUser();
  const { canApprove } = useRoles();
  const { addToast } = useContext(ToasterContext);
  const [updateSection] = useUpdateSectionMutation();

  const onApprove = async () => {
    updateSection({ reportId: savedReport, sectionId: elemSectionId, item: { is_approved: true, approval_user: userId } })
      .unwrap()
      .then(() => {
        setApprover(userData.name);
        setIsApproved(true);
        addToast({
          title: t("Section.tootlip.approvedSection"),
          type: "success",
          icon: "Success",
        });
      })
      .catch(() => true);
  };

  return (
    <ApproveSectionWrapper>
      <Button
        data-testid="ApproveButton"
        icon="ValidatedCheck"
        mode="contained"
        color="accent"
        disabled={isChanged || isApproved || !isReady || !canApprove}
        onClick={onApprove}
      >
        {t("Section.approve")}
      </Button>
    </ApproveSectionWrapper>
  );
};

export default ApproveSection;
