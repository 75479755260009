import { Badge, Button, ButtonText, SignalColor, Text } from "@secuis/ccp-react-components";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useSection } from "../../../../hooks/useSection";
import { SECTION_STATUS, Section as SectionModel } from "../../../../models/SectionModel";
import { getColor } from "../../../../utilities/sectionUtils";
import { PreviewModal } from "../Preview/PreviewModal";
import { BadgeSection, SectionButton, SectionComponent, SectionHeader, SectionPreview } from "./styled";

interface SectionProps {
  elem: SectionModel;
  isExistingReport: boolean;
  report_type: string;
  report_id: string;
}

export const Section: FC<SectionProps> = ({ elem, isExistingReport, report_type, report_id }) => {
  const { t } = useTranslation();
  const [color, setColor] = useState<SignalColor>("neutral");
  const navigate = useNavigate();
  const { setSection } = useSection();
  const [showPreview, setShowPreview] = useState(false);

  const navigateToCreateSection = () => {
    setSection(elem);
    const to = {
      pathname: "/createSection",
      search: new URLSearchParams({ type: report_type, id: report_id, section: elem.id || "" }).toString(),
    };
    navigate(to);
  };

  useEffect(() => {
    setColor(getColor(elem.status));
  }, [elem.status]);

  return (
    <SectionComponent key={elem.name}>
      <SectionHeader>
        <Text>{t(`tag.${elem.name}.region`)}</Text>
        <BadgeSection disabled={!isExistingReport}>
          <Badge label={t(`Section.status.${elem.status}`)} mode="outlined" color={color} style={{ opacity: elem.status === SECTION_STATUS.EMPTY ? 0.7 : 1 }} />
        </BadgeSection>
      </SectionHeader>
      <SectionButton>
        <Button mode="contained" disabled={!isExistingReport} onClick={navigateToCreateSection}>
          {elem.status === SECTION_STATUS.EMPTY ? t("Section.create") : t("Section.open")}
        </Button>
      </SectionButton>

      <SectionPreview disabled={!isExistingReport || elem.status === SECTION_STATUS.EMPTY}>
        <ButtonText onClick={() => setShowPreview(true)} disabled={!isExistingReport || elem.status === SECTION_STATUS.EMPTY} color="accent">
          {t("Section.preview")}
        </ButtonText>
      </SectionPreview>
      <PreviewModal htmlContent={[elem.content]} isOpen={showPreview} setIsOpen={setShowPreview} />
    </SectionComponent>
  );
};
