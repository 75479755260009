import styled from "styled-components";
import { Breakpoints, Palette, Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";

export const SettingsContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.8fr 1.6fr;
  gap: ${Spacing.M}px;

  padding: ${Spacing.L}px 0 ${Spacing.XS}px 0;

  @media (max-width: ${Breakpoints.XS}) {
    padding: ${Spacing.S}px 0 ${Spacing.XS}px 0;
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled(Stack).attrs({
  direction: "column",
  p: ["M", "M", "S"],
})`
  background-color: ${Palette.Navy750};
  border-radius: 4px;
`;

export const SliderComponent = styled.div`
  display: grid;
  gap: ${Spacing.M}px;
  grid-template-columns: 3fr 2fr;
`;

// TODO: what's the point of having this custom separator? we should follow Design System guidelines and use the default one
export const CustomSeparator = styled(Separator)`
  background-color: ${Palette.Navy300};
  margin: ${Spacing.S}px 0;
  opacity: 0.7;

  @media (max-width: ${Breakpoints.S}) {
    margin: ${Spacing.XS}px 0;
  }
`;

export const ToggleWrapper = styled(Stack).attrs({
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "XS",
})`
  ${Text} {
    min-width: 70px;
    max-width: 70px;
  }
`;

export const LoadingWrapper = styled(Stack).attrs({
  justifyContent: "center",
  alignItems: "center",
})`
  height: 100%;
`;

export const ApproverLoadingWrapper = styled(Stack)`
  height: 100%;
`;
