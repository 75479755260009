import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  hasAccess: boolean | null;
}

export const ProtectedRoute = ({ hasAccess }: ProtectedRouteProps) => {
  if (hasAccess === null) return;

  return !hasAccess ? <Navigate to="/" replace /> : <Outlet />;
};
