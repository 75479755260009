import { Dropdown } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReportTypes } from "../../../hooks/useReportTypes";
import { transformNames } from "../../../utilities/utils";

interface CreateReportDropdownProps {
  placeholderTitle?: string;
  onChange?: (value: string) => void;
  reportsFilter?: string[];
}

export const CreateReportDropdown = ({ onChange, reportsFilter, placeholderTitle = "CreateReport.dropdown.createReport" }: CreateReportDropdownProps) => {
  const [placeholder, setPlaceholder] = useState("PLACEHOLDER");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filteredReportsTypes } = useReportTypes();
  const [dropdown, setDropdown] = useState([
    {
      title: t("CreateReport.dropdown.createReport"),
      value: placeholder,
    },
  ]);

  useEffect(() => {
    if (filteredReportsTypes?.names?.length > 0) {
      const elem = filteredReportsTypes?.names?.map((type) => ({
        title: t(`reports.${transformNames(type)}.title`),
        value: type,
      }));
      let newReportTypes;
      if (reportsFilter?.length > 0) {
        newReportTypes = elem.filter((reportType) => reportsFilter.includes(reportType?.value));
      } else {
        newReportTypes = elem;
      }
      setDropdown(
        [
          {
            title: t(placeholderTitle),
            value: placeholder,
          },
        ].concat(newReportTypes)
      );
    }
  }, [filteredReportsTypes?.names, t]);

  const handleOnChange = async (value: string) => {
    if (onChange) {
      setPlaceholder(value);
      onChange(value);
    } else {
      if (value !== placeholder) {
        const to = { pathname: "/createReport", search: new URLSearchParams({ type: value }).toString() };
        navigate(to);
      }
    }
  };

  return (
    <Dropdown
      data-testid="dropdown"
      onChange={handleOnChange}
      placeholder={t("CreateReport.dropdown.createReport")}
      sheetTitle={t("CreateReport.dropdown.createReport")}
      title={t("CreateReport.dropdown.createReport")}
      options={dropdown}
      sheetCancelLabel="Cancel"
      value={placeholder}
    />
  );
};
