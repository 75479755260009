import { Box, Button, ButtonText, Checkbox, DropdownCheckbox, DropdownCheckboxOption, Separator, Stack, Text } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters } from "../../../../hooks/useFilters";
import { useReportTypes } from "../../../../hooks/useReportTypes";
import { useRoles } from "../../../../hooks/useRoles";
import { useTagsAndRegions } from "../../../../hooks/useTagsAndRegions";
import { useAppDispatch } from "../../../../store";
import { reportsActions } from "../../../../store/reports/ReportsSlice";
import { createNewFilters, deepEqual } from "../../../../utilities/filtersUtils";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { transformNames } from "../../../../utilities/utils";
import { DateNavigator } from "../../../DatePicker/DateNavigator";
import { ToolbarContentWrapper, ToolbarFooter, ToolbarFooterWrapper } from "../Toolbar.styles";
import { useToolbar } from "../ToolbarHooks";
import { DropdownWrapper, DropdownWrapperFixed, UnselectAllLink } from "./FiltersContent.styles";

export const FiltersContent = () => {
  const { t } = useTranslation();
  const { tagNames } = useTagsAndRegions();
  const { couldCreateReport } = useRoles();
  const { readableReportsTypes } = useReportTypes();
  const { filters, updateFilters, resetFilters } = useFilters();
  const dispatch = useAppDispatch();
  const [isChanged, setIsChanged] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [options, setOptions] = useState<DropdownCheckboxOption[]>([]);
  const [onlyFlagged, setOnlyFlagged] = useState(filters?.only_flagged);
  const [onlyUnpublished, setOnlyUnpublished] = useState(filters?.only_unpublished);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const { isFilterSelectorExpanded, setIsFilterSelectorExpanded } = useToolbar();

  useEffect(() => {
    setCheckedItems(readableReportsTypes?.filter((option) => filters.report_types.includes(option)));
  }, [readableReportsTypes]);

  const handleCheckboxChange = (option) => {
    if (checkedItems.find((element) => element === option)) {
      setCheckedItems(checkedItems.filter((ele) => ele !== option));
    } else {
      setCheckedItems([...checkedItems, option]);
    }
  };

  const handleUnselectAllReports = () => {
    setCheckedItems(checkedItems.length === readableReportsTypes.length ? [] : readableReportsTypes);
  };

  const handleUnselectAllTags = () => {
    setDropdown(dropdown.length === tagNames.length ? [] : tagNames);
  };

  useEffect(() => {
    if (tagNames.length > 0) {
      setOptions(
        tagNames?.map((elem) => ({
          value: elem,
          title: getTranslatedTag(elem),
        }))
      );
      setDropdown(filters.tags);
    }
  }, [tagNames]);

  const applyFilters = () => {
    const newFilters = createNewFilters({ startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems });
    dispatch(reportsActions.setPage(1));
    updateFilters(newFilters);
  };

  const resetFiltersHandler = () => {
    resetFilters();
    setOnlyFlagged(false);
    setOnlyUnpublished(false);
    setStartDate(null);
    setEndDate(null);
    dispatch(reportsActions.setPage(1));
    setCheckedItems([]);
    setDropdown([]);
  };

  useEffect(() => {
    const newFilters = createNewFilters({ startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems });
    setIsChanged(!deepEqual(filters, newFilters));
  }, [startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems, filters]);

  return (
    <>
      <ToolbarContentWrapper $isFilterSelectorExpanded={isFilterSelectorExpanded}>
        <Separator mv="S" mh="0" />
        <Stack direction="column" alignItems="flex-start">
          {couldCreateReport && (
            <Stack alignItems="center" mb="S">
              <Checkbox data-testid="unpublished-only" checked={onlyUnpublished} id="unpublished-only" onChange={() => setOnlyUnpublished(!onlyUnpublished)} />
              <Text>{t("Filter.showUnpublishedOnly")}</Text>
            </Stack>
          )}
          <Stack alignItems="center">
            <Checkbox data-testid="show-only-flagged" checked={onlyFlagged} id="show-only-flagged" onChange={() => setOnlyFlagged(!onlyFlagged)} />
            <Text>{t("Filter.showOnlyFlagged")}</Text>
          </Stack>
        </Stack>
        <Separator mv="S" mh="0" />
        <Stack justifyContent="space-between" pb="S">
          <Text bold>{t("Filter.reports")}</Text>
          <UnselectAllLink data-testid={`report-type-unselect`} onClick={handleUnselectAllReports}>
            {checkedItems.length !== readableReportsTypes.length ? t("reports.selectAll") : t("reports.unselectAll")}
          </UnselectAllLink>
        </Stack>
        <Stack direction="column" gap="S">
          {readableReportsTypes.map((elem) => (
            <Checkbox
              data-testid={`report-type-${elem}`}
              key={`report-type-${elem}`}
              checked={checkedItems.includes(elem)}
              bold={false}
              id={`filter-checkbox-${elem}`}
              label={t(`reports.${transformNames(elem)}.title`)}
              onChange={() => handleCheckboxChange(elem)}
            />
          ))}
        </Stack>
        <DropdownWrapperFixed>
          <Separator mv="S" mh="0" />
          <Stack justifyContent="space-between" pb="S">
            <Text bold>{t("Filter.tagsAndCategories")}</Text>
            <UnselectAllLink data-testid={`tags-unselect`} onClick={handleUnselectAllTags}>
              {dropdown.length === tagNames.length ? t("reports.unselectAll") : t("reports.selectAll")}
            </UnselectAllLink>
          </Stack>
          <DropdownCheckbox
            onExpandChange={setIsFilterSelectorExpanded}
            onChange={(val) => setDropdown(val)}
            options={options}
            sheetCancelLabel="Cancel"
            values={dropdown}
            placeholder={t("filter.selectCategories")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapper>
          <Separator mv="S" mh="0" />
          <Box pb="S">
            <Text bold>{t("Filter.date")}</Text>
          </Box>
          <DateNavigator
            onExpandChange={setIsFilterSelectorExpanded}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            placeholder={t("CreateReport.schedule.selectDate")}
            setEndDate={setEndDate}
          />
        </DropdownWrapper>
      </ToolbarContentWrapper>
      <ToolbarFooter>
        <ToolbarFooterWrapper>
          <Button mode="contained" color="accent" disabled={!isChanged} onClick={applyFilters}>
            {t("filter.applyFilter")}
          </Button>
          <ButtonText color="accent" onClick={resetFiltersHandler}>
            {t("Filter.reset")}
          </ButtonText>
        </ToolbarFooterWrapper>
      </ToolbarFooter>
    </>
  );
};
