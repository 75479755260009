import { createApi } from "@reduxjs/toolkit/query/react";
import { REPORT_STATUS } from "../../models/ReportModel";
import { baseQuery } from "../../utilities/baseQuery";
import { DraftsState } from "./DraftsSlice";

export const draftsApi = createApi({
  reducerPath: "draftsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Draft"],
  endpoints: (builder) => ({
    fetchDrafts: builder.query<DraftsState, void>({
      query: () => ({
        url: `/reports?report_status=${REPORT_STATUS.DRAFT}`,
      }),
      providesTags: ["Draft"],
    }),
  }),
});

export const { useLazyFetchDraftsQuery } = draftsApi;
