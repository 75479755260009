import styled from "styled-components";
import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";

export const Container = styled.div`
  float: right;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  top: 30px;
  right: ${Spacing.M}px;

  div {
    position: absolute;
    z-index: 3;
    top: -8px;
    right: -4px;
    background-color: ${Palette.Purple300};
    border-color: ${Palette.Navy750};
    color: ${Palette.Navy750};
  }

  @media (max-width: ${Breakpoints.XS}) {
    top: ${Spacing.S}px;
    right: 0;
  }
`;
