import styled from "styled-components";
import { Accordion, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";

export const ListContainer = styled(Stack).attrs({
  direction: "column",
  justifyContent: "space-between",
  gap: "XS",
})`
  padding: ${Spacing.L}px 0 ${Spacing.XS}px 0;
  color: ${Palette.Gray100};
  position: relative;
  min-width: 1px;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${Breakpoints.XS}) {
    padding: ${Spacing.S}px 0 ${Spacing.XS}px 0;
  }
`;

export const CustomAccordion = styled(Accordion)`
  background-color: ${Palette.Navy750};
  white-space: pre-line;
`;
