import { Stack } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSchedule } from "../../../../hooks/useSchedule";
import { DeleteReport } from "../../List/ReportActions/DeleteReport";
import PreviewButton from "../Preview/PreviewButton";
import { ReportTitle } from "../ReportTitle";
import ApproveSections from "./ApproveSections";
import CancelSections from "./CancelSections";
import SaveReport from "./SaveReport";

const ReportActionsSections = ({
  isChanged,
  scheduleDate,
  htmlContent,
  savedReport,
  reportType,
  setTitle,
  initPrefix,
  isApproved,
  isScheduleDateFromFuture,
  setIsApproved,
  uploadedScheduleDate,
  setSavedReport,
  setUploadedData,
  title,
  reportCreation,
  areSectionsInProgress,
  areTagsEmpty,
}) => {
  const { i18n } = useTranslation();
  const { getSchedule } = useSchedule();
  const navigate = useNavigate();

  const navigateToReports = () => navigate("/reports", { replace: true });

  return (
    <>
      <Stack gap="L">
        <div>
          <CancelSections isContentChanged={isChanged} navigateTo={navigateToReports} />
        </div>
        <div>
          <PreviewButton disabled={!savedReport} htmlContent={htmlContent} />
        </div>
        <div>
          <SaveReport
            scheduleDate={scheduleDate}
            isChanged={isChanged}
            savedReport={savedReport}
            reportType={reportType}
            setIsApproved={setIsApproved}
            title={title}
            reportCreation={reportCreation}
            setSavedReport={setSavedReport}
            setUploadedData={setUploadedData}
            uploadedScheduleDate={uploadedScheduleDate}
            setTitle={setTitle}
          />
        </div>
        <div>
          <ApproveSections
            isChanged={isChanged}
            scheduleDate={scheduleDate}
            savedReport={savedReport}
            isApproved={isApproved}
            setIsApproved={setIsApproved}
            isScheduleDateFromFuture={isScheduleDateFromFuture}
            uploadedScheduleDate={uploadedScheduleDate}
            reportType={reportType}
            areSectionsInProgress={areSectionsInProgress}
            htmlContent={htmlContent}
            areTagsEmpty={areTagsEmpty}
          />
        </div>
      </Stack>
      {savedReport && (
        <Stack justifyContent="flex-end">
          <DeleteReport reportId={savedReport} />
        </Stack>
      )}
      {!getSchedule(reportType) && (
        <ReportTitle setTitle={setTitle} type={reportType} scheduleDate={scheduleDate} lang={i18n.language} initPrefix={initPrefix} />
      )}
    </>
  );
};

export default ReportActionsSections;
