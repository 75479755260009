import { Button, ButtonIcon, Dropdown, EmptyState, Skeleton, Stack, Text, ToasterContext } from "@secuis/ccp-react-components";
import { add, compareAsc, startOfTomorrow } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRoles } from "../../../../hooks/useRoles";
import { useScheduleDate } from "../../../../hooks/useScheduleDate";
import { SENDOUT_STATUS, SENDOUT_TYPE, SendoutType } from "../../../../models/SendoutModel";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectItemsCollection, selectItemsCollectionDate, selectItemsCollectionType, selectMetadata } from "../../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../../store/items/ItemsSlice";
import {
  useGetSendoutQuery,
  useLazyGetSendoutsQuery,
  usePostSendoutMutation,
  usePublishSendoutMutation,
  useUpdateSendoutMutation,
} from "../../../../store/sendouts/SendoutsApi";
import {
  selectSendoutByEditedId,
  selectSendoutBySelectedId,
  selectSendoutsEditedId,
  selectSendoutsFilters,
  selectSendoutsSelectedId,
} from "../../../../store/sendouts/SendoutsSelectors";
import { sendoutsActions } from "../../../../store/sendouts/SendoutsSlice";
import { toolbarActions } from "../../../../store/toolbar/ToolbarSlice";
import { getLocalDate } from "../../../../utilities/dateUtils";
import { ScheduleTimeComponent } from "../../../DatePicker/ScheduleTimeComponent";
import { ToolbarContentWrapper, ToolbarFooter, ToolbarFooterWrapper } from "../Toolbar.styles";
import { RegionsWrapper, SendoutPreferences } from "./CollectedBriefs.style";
import { RegionBrief } from "./RegionBrief";

export const CollectedBriefs = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addToast } = useContext(ToasterContext);
  const { sendoutId } = useParams();
  const { canApprove } = useRoles();

  const { region_daily } = useAppSelector(selectMetadata);
  const collectedItems = useAppSelector(selectItemsCollection);
  const selectedSendout = useAppSelector(selectSendoutBySelectedId);
  const editedSendout = useAppSelector(selectSendoutByEditedId);
  const selectedSendoutId = useAppSelector(selectSendoutsSelectedId);
  const editedSendoutId = useAppSelector(selectSendoutsEditedId);
  const filters = useAppSelector(selectSendoutsFilters);

  const reportType = useAppSelector(selectItemsCollectionType);
  const sendoutDate = useAppSelector(selectItemsCollectionDate);

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(startOfTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(startOfTomorrow());
  const [sendoutScheduleDate, setSendoutScheduleDate] = useState<Date>();

  const { scheduleDate } = useScheduleDate("sendouts", reportType);

  const [postSendout] = usePostSendoutMutation();
  const [publishSendout, { isLoading: isPublishSendoutLoading }] = usePublishSendoutMutation();
  const [updateSendout, { isLoading: isUpdateSendoutLoading }] = useUpdateSendoutMutation();
  const [getSendouts] = useLazyGetSendoutsQuery();

  const { data: fetchedData, isFetching } = useGetSendoutQuery({ sendoutId: selectedSendoutId }, { skip: !selectedSendoutId, refetchOnMountOrArgChange: true });

  const isCancelledOrPublished = useMemo(
    () => !isFetching && fetchedData && (fetchedData.status === SENDOUT_STATUS.canceled || fetchedData.status === SENDOUT_STATUS.published),
    [fetchedData, isFetching]
  );

  useEffect(() => {
    if (sendoutDate) {
      setSendoutScheduleDate(new Date(sendoutDate));
    } else if (scheduleDate && !selectedSendoutId && !editedSendoutId) {
      setSendoutScheduleDate(new Date(scheduleDate));
    } else if (scheduleDate === null) {
      setSendoutScheduleDate(scheduleDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(scheduleDate), sendoutDate, selectedSendoutId, editedSendoutId]);

  const isTooLateToPublish = useMemo(() => compareAsc(new Date(sendoutScheduleDate), add(Date.now(), { minutes: 5 })) < 0, [sendoutScheduleDate]);

  useEffect(() => {
    if (selectedSendoutId && !sendoutId) {
      return () => {
        dispatch(sendoutsActions.setSelectedId(null));
        selectedSendoutId && dispatch(itemsActions.emptyCollection());
      };
    }
  }, [selectedSendoutId, sendoutId]);

  useEffect(() => {
    if (sendoutId) {
      dispatch(sendoutsActions.setSelectedId(sendoutId));
    }
  }, [sendoutId]);

  useEffect(() => {
    selectedSendoutId && dispatch(itemsActions.setCollectedItemsFromSendout(fetchedData?.items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData?.items, selectedSendoutId]);

  useEffect(() => {
    editedSendoutId &&
      dispatch(
        toolbarActions.setToolbar({
          visible: true,
          contentType: "collection",
          storeType: "items",
          isOpen: true,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedSendoutId]);

  useEffect(() => {
    if (selectedSendout?.schedule || editedSendout?.schedule) {
      setSendoutScheduleDate(new Date(selectedSendout?.schedule ?? editedSendout?.schedule));
      setStartDate(new Date(selectedSendout?.schedule ?? editedSendout?.schedule));
      setEndDate(new Date(selectedSendout?.schedule ?? editedSendout?.schedule));
    }
    (selectedSendout?.type || editedSendout?.type) && dispatch(itemsActions.setCollectionType((selectedSendout?.type ?? editedSendout?.type) as SendoutType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSendout, editedSendout]);

  const handleSelectReportType = (value: string) => {
    selectedSendoutId ? handleUpdateSendout({ reportType: value as SendoutType }) : dispatch(itemsActions.setCollectionType(value as SendoutType));
  };

  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  const setSendoutDate = (date: Date) => {
    selectedSendoutId ? handleUpdateSendout({ sendoutScheduleDate: date.toISOString() }) : dispatch(itemsActions.setCollectionDate(date.toISOString()));
  };

  const handleCreateSendout = () => {
    postSendout({
      schedule: sendoutScheduleDate,
      type: reportType,
      item_ids: collectedItems.map((item) => item.id),
    })
      .unwrap()
      .then(({ id }) => {
        dispatch(itemsActions.emptyCollection());
        addToast({
          title: t("Items.pickToSendout.toast.success"),
          type: "success",
          icon: "Success",
        });
        dispatch(sendoutsActions.resetFilters());
        navigate(`/editSendout/${id}`);
      })
      .catch(() => true);
  };

  const handlePublishSendout = () => {
    publishSendout({
      sendoutId: selectedSendoutId,
      schedule: sendoutScheduleDate,
      type: reportType,
    })
      .unwrap()
      .then(() => {
        addToast({
          title: t("Sendout.published.toast.success"),
          type: "success",
          icon: "Success",
        });
        getSendouts({ page: 1, filters });
      })
      .catch(() => true);
  };

  interface UpdateSendoutProps {
    reportType?: SendoutType;
    sendoutScheduleDate?: string;
  }

  const buildUpdateParameters = ({ reportType, sendoutScheduleDate }: UpdateSendoutProps) => {
    const parameters = {
      sendoutId: selectedSendoutId,
      status: SENDOUT_STATUS.draft,
    };
    if (reportType) parameters["type"] = reportType;
    if (sendoutScheduleDate) parameters["schedule"] = sendoutScheduleDate;
    return parameters;
  };

  const handleUpdateSendout = ({ reportType, sendoutScheduleDate }: UpdateSendoutProps) => {
    const parameters = buildUpdateParameters({ reportType, sendoutScheduleDate });
    updateSendout({ ...parameters })
      .unwrap()
      .then(() => {
        addToast({
          title: t("Sendout.updated.toast.success"),
          type: "success",
          icon: "Success",
        });
      })
      .catch(() => true);
  };

  const submitSendoutButton = useMemo(
    () => ({
      title: selectedSendoutId ? t("Sendout.publish") : t("Briefs.collection.sendOut"),
      onclick: selectedSendoutId ? handlePublishSendout : handleCreateSendout,
      disabled:
        isFetching ||
        !reportType ||
        !sendoutScheduleDate ||
        (selectedSendoutId && fetchedData?.status !== SENDOUT_STATUS.draft) ||
        (selectedSendoutId && isTooLateToPublish) ||
        (selectedSendoutId && !canApprove) ||
        collectedItems?.length === 0 ||
        isUpdateSendoutLoading ||
        isPublishSendoutLoading ||
        isCancelledOrPublished,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedSendoutId,
      isFetching,
      reportType,
      sendoutScheduleDate,
      fetchedData?.status,
      isUpdateSendoutLoading,
      isTooLateToPublish,
      collectedItems?.length,
      canApprove,
      isPublishSendoutLoading,
      isCancelledOrPublished,
    ]
  );

  const isDisabled = useMemo(
    () => !!editedSendoutId || isUpdateSendoutLoading || isPublishSendoutLoading || isCancelledOrPublished || isFetching,
    [editedSendoutId, isCancelledOrPublished, isFetching, isPublishSendoutLoading, isUpdateSendoutLoading]
  );

  return (
    <>
      <ToolbarContentWrapper $isCollection>
        <Stack direction="column" flex={1}>
          {region_daily.length > 0 && (
            <RegionsWrapper>
              {!isFetching && !collectedItems?.length && <EmptyState icon="Risk" size="M" title={t("Sendout.items.emptyState")} />}
              {region_daily.map((region) => (
                <RegionBrief key={region} region={region} isFetching={isFetching} />
              ))}
            </RegionsWrapper>
          )}
        </Stack>
      </ToolbarContentWrapper>
      <ToolbarFooter>
        <ToolbarFooterWrapper>
          <SendoutPreferences>
            <Text>{t("Briefs.collection.sendOutPreferences")}</Text>
            {isFetching && <Skeleton height={52} mode="rectangular" width={288} />}
            {!isFetching && (
              <Dropdown
                disabled={isDisabled}
                value={reportType}
                placeholder={t("CreateReport.dropdown.createReport")}
                sheetTitle={t("CreateReport.dropdown.createReport")}
                title={t("CreateReport.dropdown.createReport")}
                onChange={handleSelectReportType}
                options={Object.keys(SENDOUT_TYPE).map((type) => ({ value: type, title: t(`Sendout.${type}.title`) }))}
                sheetCancelLabel="Cancel"
              />
            )}
            {isFetching && <Skeleton height={32} mode="rectangular" width={288} />}
            {!isFetching && (
              <Stack direction="row" flex={1} justifyContent="space-between" alignItems="center">
                <Stack alignItems="center" gap="XS">
                  <ButtonIcon iconSize="XL" mode="stateless" icon="Calendar" color="neutral" onClick={showCalendar} disabled={isDisabled} />
                  <Text data-testid="eventDate" bold truncate disabled={isDisabled}>
                    {sendoutScheduleDate ? getLocalDate(sendoutScheduleDate, i18n.language) : t("CreateReport.schedule.selectDate")}
                  </Text>
                </Stack>
                <ScheduleTimeComponent
                  disabled={isDisabled}
                  data-testid="ItemNavigator-ShowCalendar"
                  showCalendar={showCalendar}
                  hideCalendar={hideCalendar}
                  isOpen={isCalendarOpen}
                  scheduleDate={sendoutScheduleDate}
                  setScheduleDate={setSendoutDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  lang={i18n.language}
                  blockPastDays={0}
                  width="auto"
                  placement="top"
                />
              </Stack>
            )}
            <Stack>
              {editedSendoutId && (
                <Stack direction="column" gap="S" flex={1}>
                  <Text micro color="disabled">
                    {t("Sendout.collection.edit.info")}
                  </Text>
                  <Button
                    mode="contained"
                    color="accent"
                    title={t("Sendout.collection.cancelEdit")}
                    icon="Edit"
                    onClick={() => navigate(`/editSendout/${editedSendoutId}`)}
                  >
                    {t("Sendout.collection.cancelEdit")}
                  </Button>
                </Stack>
              )}
              {!editedSendoutId && (
                <Stack direction="column" gap="S" flex={1}>
                  {selectedSendoutId && isTooLateToPublish && (
                    <Text micro color="disabled">
                      {t("Sendout.collection.publish.tooLate")}
                    </Text>
                  )}
                  <Button
                    disabled={submitSendoutButton.disabled}
                    color="accent"
                    mode="contained"
                    title={submitSendoutButton.title}
                    onClick={submitSendoutButton.onclick}
                  >
                    {submitSendoutButton.title}
                  </Button>
                </Stack>
              )}
            </Stack>
          </SendoutPreferences>
        </ToolbarFooterWrapper>
      </ToolbarFooter>
    </>
  );
};
