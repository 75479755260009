import { format, startOfDay } from "date-fns";
import { REPORT_STATUS } from "../models/ReportModel";
import { CreateNewFiltersProps } from "./types";

export const createNewFilters = ({ startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems }: CreateNewFiltersProps) => {
  const startOfDayDate = (date) => startOfDay(date).getTime();
  const dates = startDate && endDate ? [startOfDayDate(startDate), startOfDayDate(endDate)] : [];
  return {
    dates,
    tags: dropdown,
    only_flagged: onlyFlagged,
    only_unpublished: onlyUnpublished,
    report_types: checkedItems,
  };
};

export const createUrl = ({ filters, tagNames, reportsTypes, couldCreateReport }) => {
  let result = "";
  let report_status = !couldCreateReport ? [REPORT_STATUS.DELIVERED] : [REPORT_STATUS.DELIVERED, REPORT_STATUS.PUBLISHED];

  if (filters.only_flagged) {
    result += "&flagged=true";
  }

  if (filters.only_unpublished) {
    report_status = [REPORT_STATUS.PUBLISHED];
  }
  result += report_status.length > 0 ? "&report_status=" + report_status.join("&report_status=") : "";

  if (filters.tags.length > 0 && filters.tags.length < tagNames.length) {
    result += `&tags=${filters.tags.join("&tags=").replace("_&_", "_%26_")}`;
  }

  if (filters.report_types.length > 0 && filters.report_types.length < reportsTypes.length) {
    result += `&report_type=${filters.report_types.join("&report_type=")}`;
  }

  if (filters.dates.length > 1) {
    result += `&start_date=${format(new Date(filters.dates[0]), "yyyy-MM-dd")}&end_date=${format(new Date(filters.dates[1]), "yyyy-MM-dd")}`;
  }

  return result && result.startsWith("&") ? result.substring(1) : result;
};

export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
