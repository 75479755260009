import { Button, ToasterContext } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../../hooks/useUserData";
import { Section } from "../../../../models/SectionModel";
import { useCreateSectionMutation, useUpdateSectionMutation } from "../../../../store/reports/ReportsApi";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { areTagsChanged } from "../../../../utilities/utils";

const SaveSection = ({
  isChanged,
  savedReport,
  reportType,
  htmlContent,
  tags,
  setIsApproved,
  setUploadedDataSectionData,
  uploadedHtmlContent,
  savedTags,
  name,
  sectionIdElem,
  setElemSectionId,
  setIsReady,
  setCreator,
}) => {
  const { t } = useTranslation();
  const { userId } = useUserData();
  const { userData } = useLoggedUser();
  const { addToast } = useContext(ToasterContext);
  const [isCreationInProgress, setIsCreationInProgress] = useState(false);

  const [createSection] = useCreateSectionMutation();
  const [updateSection] = useUpdateSectionMutation();
  const saveReport = async () => {
    try {
      if (!sectionIdElem) {
        setIsCreationInProgress(true);
        const content = {
          name: name,
          content: htmlContent || "",
          order: 1,
          lang: "en",
          author: userId,
          is_done: false,
          is_approved: false,
          tags: tags.filter((elem) => elem.categories.length > 0),
        };
        const response = await createSection({ reportId: savedReport, item: content }).unwrap();

        const sectionId = response.id;

        addToast({
          title: t("CreateReport.toast.createSection", {
            type: t(`tag.${name}.region`),
          }),
          type: "success",
          icon: "Success",
        });
        setIsApproved(false);

        setUploadedDataSectionData(tags, content.content);
        setCreator(userData.name);

        if (sectionId) {
          setElemSectionId(sectionId);
        }
        setIsCreationInProgress(false);
      } else {
        const content: Section = {};

        if (htmlContent !== uploadedHtmlContent) {
          content.content = htmlContent;
        }

        if (areTagsChanged(tags, savedTags)) {
          content.tags = tags.filter((elem) => elem.categories.length > 0);
        }

        await updateSection({ reportId: savedReport, sectionId: sectionIdElem, item: content }).unwrap();
        setUploadedDataSectionData(tags, htmlContent);
        const sectionName = t(`tag.${name}.region`);
        const type = `${reportType}: ${sectionName}\n`;
        addToast({
          title: t("CreateReport.toast.updateSection", {
            type,
          }),
          type: "success",
          icon: "Success",
        });

        setIsReady(false);
        setIsApproved(false);
      }
    } catch (e) {
      setIsCreationInProgress(false);
      addToast({ title: t("Section.tootlip.updateFailed"), type: "high", icon: "Error" });
    }
  };

  return (
    <Button
      data-testid="SaveReportButton"
      icon="Archive"
      mode="contained"
      color="accent"
      disabled={!isChanged || isCreationInProgress}
      onClick={async () => saveReport()}
    >
      {t("Section.save")}
    </Button>
  );
};

export default SaveSection;
