import { useEffect } from "react";
import { useStore } from "react-context-hook";

import { useAppSelector } from "../store";
import { selectDrafts } from "../store/drafts/DraftsSelectors";
import { useLazyFetchSectionsQuery } from "../store/reports/ReportsApi";

export const useDraftSections = () => {
  const [draftSections, setDraftSections] = useStore("draftSections", {});

  const draftReports = useAppSelector(selectDrafts);

  const [fetchSections] = useLazyFetchSectionsQuery();

  const getDraftSections = async ({ reportId }: { reportId: string }) => {
    if (!reportId) {
      return;
    }

    try {
      const result = await fetchSections({ reportId }).unwrap();
      return { [reportId]: (result as any)?.data };
    } catch (error) {
      console.error("Error fetching sections:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (draftReports.length > 0) {
      Promise.all(draftReports.map(({ report_id }) => getDraftSections({ reportId: report_id })))
        .then((values) => setDraftSections(values.reduce((acc, i) => ({ ...acc, [Object.keys(i)[0]]: i[Object.keys(i)[0]] }), {})))
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftReports]);

  return { draftSections };
};
