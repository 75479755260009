import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { keyframes } from "styled-components";

const inAnimation = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;
export type WidthType = '100%' | 'auto';
export const Wrapper = styled.div<{ $width: WidthType }>`
  width: ${({ $width }) => $width};
`;

export interface ContentProps {
  offsetTop?: number;
  offsetLeft?: number;
  offsetRight?: number;
}

export const Content = styled.div<ContentProps>`
  position: absolute;
  animation: ${inAnimation} 100ms ease-in-out;
  ${({ offsetTop }) => Number.isInteger(offsetTop) && `top: ${offsetTop}px;`}
  ${({ offsetLeft }) => Number.isInteger(offsetLeft) && `left: ${offsetLeft}px;`}
    ${({ offsetRight }) => Number.isInteger(offsetRight) && `right: ${offsetRight}px;`}

    @media (max-width: ${Breakpoints.XS}) {
    top: 0;
    left: 0;
    right: unset;
  }
`;

export const ScheduleTime = styled(Stack).attrs({
  alignItems: "center",
  justifyContent: "center",
  mt: "XXS",
  p: "M",
})`
  background-color: ${Palette.Navy700};
  border-radius: ${Spacing.XXS}px;

  input {
    width: 80px;
    text-align: center;
    padding: 0;
  }

  input + div {
    display: none;
  }

  button {
    height: 40px;
    width: 40px;
  }
`;

export const TimeInputs = styled(Stack).attrs({
  mr: "M"
})`
  min-height: 48px;
`;

export const TimeSeparator = styled(Stack).attrs({
  mh: "XS"
})`
  align-self: center;
`;
