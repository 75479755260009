import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { ErrorModel } from "../../models/ErrorModel";
import { appActions } from "../app/AppSlice";

const ErrorTitlesPerEndpoint = {
  exportItems: "Items.exportToCsv.toast.failed",
  patchItemStatus: "Item.toast.updateFailed",
  deleteItem: "settings.notification.failed",
  postSendout: "Items.pickToSendout.toast.failed",
  deleteAttachment: "settings.notification.failed",
  deleteReport: "settings.notification.failed",
  shareReport: "share.reportShareFailed",
  shareBrief: "share.briefShareFailed",
  updateSection: "Section.tootlip.updateFailed",
};

const errorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const { endpointName } = action.meta.arg as { endpointName: string };
      const errorMessage = ErrorTitlesPerEndpoint[endpointName] ?? "errors.fetchError.title";
      const { status, data } = action.payload as { status: number; data: { detail: string } };
      if (status !== 401 && status !== 404) {
        const error: ErrorModel = {
          title: errorMessage,
          message: errorMessage === "errors.fetchError.title" ? data?.detail : undefined,
          timestamp: new Date().getTime(),
          status: (action.payload as { status: number }).status,
        };
        dispatch(appActions.addError(error));
      }
    }

    return next(action);
  };

export default errorMiddleware;
