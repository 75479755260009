import { useContext, useEffect } from "react";
import { EMPTY_USER_ID } from "../../constants/userIds";
import { useAppDispatch, useAppSelector } from "../../store";
import { getUserName } from "../../utilities/utils";
import { useLazyGetUserByIdQuery, useUpdatePreferenceMutation } from "./UserApi";
import { selectUser } from "./UserSelectors";
import { LANGUAGE_KEY } from "../../constants/generalSettings";
import { userActions } from "./UserSlice";
import { useTranslation } from "react-i18next";
import { SettingsValue } from "../../components/Settings/Preferences";
import { ToasterContext } from "@secuis/ccp-react-components";

export const useUser = (userId: string) => {
  const user = useAppSelector(selectUser(userId));
  const [fetchUser, { isLoading, isError }] = useLazyGetUserByIdQuery();
  const [updatePreference] = useUpdatePreferenceMutation();
  const dispatch = useAppDispatch();
  const { addToast } = useContext(ToasterContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user && userId && userId !== EMPTY_USER_ID) {
      fetchUser(userId);
    }
  }, [user, userId, fetchUser]);

  const updatePreferences = (key: string, content: SettingsValue, value: string | boolean) => {
    updatePreference({ userId, preferenceKey: key, preferenceType: content.type, preferenceValue: content.value })
      .unwrap()
      .then(() => {
        dispatch(
          userActions.updatePreference({
            [key]: value,
          })
        );
        key === LANGUAGE_KEY
          ? addToast({
            title: t("toast.languageChanged.title"),
            message: t("toast.languageChanged.description"),
            type: "success",
            icon: "Success",
          })
          : addToast({ title: t("settings.notification.success"), type: "success", icon: "Success" });
      })
      .catch(() => true);
  };

  return {
    user,
    userName: getUserName(user),
    isLoading,
    isError,
    updatePreferences,
  };
};
