import { Text, ToasterContext, Toggle, Tooltip } from "@secuis/ccp-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateSectionMutation } from "../../../../store/reports/ReportsApi";
import { ToggleReadyWrapper } from "./styled";

const SectionToggle = ({ isChanged, setIsReady, isReady, elemSectionId, savedReport, noTags }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToasterContext);
  const [updateSection] = useUpdateSectionMutation();

  const getContent = () => {
    return (
      <Toggle
        data-testid="markSectionAsReady"
        disabled={isChanged || noTags}
        id="markApproved"
        checked={isReady}
        onClick={function noRefCheck() {}}
        onChange={async () => {
          await updateSection({ reportId: savedReport, sectionId: elemSectionId, item: { is_done: !isReady } })
            .unwrap()
            .then(() => {
              setIsReady(!isReady);
              addToast({
                title: !isReady ? t("Section.tootlip.readySection") : t("Section.tootlip.notReadySection"),
                type: "success",
                icon: "Success",
              });
            })
            .catch(() => true);
        }}
      />
    );
  };
  return (
    <ToggleReadyWrapper>
      {noTags ? (
        <Tooltip message={t("CreateReport.tooltipSelectTags")} place="top-start">
          {getContent()}
        </Tooltip>
      ) : (
        getContent()
      )}
      <Text>{t("Section.markAsReady")}</Text>
    </ToggleReadyWrapper>
  );
};

export default SectionToggle;
