import { Button, ToasterContext } from "@secuis/ccp-react-components";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteReportMutation } from "../../../../store/reports/ReportsApi";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";

interface DeleteReportProps {
  reportId: string;
}

export const DeleteReport: FC<DeleteReportProps> = ({ reportId }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToasterContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [deleteReport] = useDeleteReportMutation();

  const performDelete = () => {
    deleteReport({ reportId })
      .unwrap()
      .then(() => {
        addToast({ title: t("Reports.deleteReportNotfication"), type: "success", icon: "Success" });
        navigate("/reports", { replace: true });
      })
      .catch(() => true);
  };
  return (
    <>
      <div>
        <Button icon="Delete" mode="outlined" color="error" onClick={() => setIsOpen(true)}>
          {t("button.delete")}
        </Button>
      </div>
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={performDelete} title={t("Reports.deleteReport")} />
    </>
  );
};
