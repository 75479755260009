import { Palette, Breakpoints, Spacing, CountIndicator, Text } from "@secuis/ccp-react-components";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const activeLinkBorderWidth = "2px";

export const NavigationLinkStyled = styled(NavLink) <{ $isActive?: boolean }>`
  text-decoration: none;
  text-align: center;
  color: ${Palette.Gray100};

  ${Text} {
    text-align: left;
  }
    
  @media (max-width: ${Breakpoints.XS}) {
    ${Text} {
      text-align: center;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    `
        color: ${Palette.Purple300};
        & svg {
          path {
            color: ${Palette.Purple300};
          }
        }
        @media (min-width: ${Breakpoints.XS}) {
          margin-left: -${Spacing.M}px;
          padding-left: calc(${Spacing.M}px - ${activeLinkBorderWidth});
          border-left: ${activeLinkBorderWidth} solid ${Palette.Purple300};
        }
      
          `}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${Palette.Purple400};
      & svg {
        path {
          color: ${Palette.Purple300};
        }
      }
    }
  }

  &:active,
  &.active {
    color: ${Palette.Purple300};
    & svg {
      path {
        color: ${Palette.Purple300};
      }
    }
    @media (min-width: ${Breakpoints.XS}) {
      margin-left: -${Spacing.M}px;
      padding-left: calc(${Spacing.M}px - ${activeLinkBorderWidth});
      border-left: ${activeLinkBorderWidth} solid ${Palette.Purple300};
    }
  }
`;

export const CountIndicatorContainer = styled.div<{ isExpanded: boolean; isSmallScreen: boolean }>`
  ${({ isExpanded }) =>
    !isExpanded &&
    `position: absolute;
            padding-bottom: ${Spacing.L}px;
            padding-left: ${Spacing.XS}px;
            `}

  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `position: absolute;
            padding-right: ${Spacing.S}px;
            `}
`;
export const CountIndicatorItem = styled(CountIndicator)`
  border: solid 2px;
`;
