import { Breakpoints, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useReportTypes } from "../../../hooks/useReportTypes";
import { useRoles } from "../../../hooks/useRoles";
import { useAppDispatch } from "../../../store";
import { toolbarActions } from "../../../store/toolbar/ToolbarSlice";
import { CreateReportDropdown } from "../Create/CreateReportDropdown";
import { TitleWrapper, Wrapper } from "./Reports.styles";
import { ReportsList } from "./ReportsList";

export const Reports = () => {
  const { t } = useTranslation();
  const { couldCreateReport } = useRoles();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { reportsTypes } = useReportTypes();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      toolbarActions.setToolbar({
        visible: true,
        contentType: "filters",
        storeType: "reports",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t("Reports.title")}</Title>
        {couldCreateReport && !isMobile && (
          <div>
            <CreateReportDropdown />
          </div>
        )}
      </TitleWrapper>
      {reportsTypes && <ReportsList couldCreateReport={couldCreateReport} />}
    </Wrapper>
  );
};
