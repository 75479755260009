export const updateReport = async (accessToken: string, endpoint: string, content: object | string, method = "PATCH", returnBody = true) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 200 && response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }
  return returnBody ? await response.json() : true;
};
export const contactUs = async (accessToken: string, content) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/user-inquiries/contact-us`;

  const response = await fetch(requestUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 204) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return true;
};
export const getReport = async (userToken: string, reportId: string, lang: string) => {
  return await getDataFromBE(userToken, `/reports/${reportId}?lang=${lang}`);
};

export const getDataFromBE = async (accessToken: string, endpoint: string) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 200 && response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return await response.json();
};
