import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useMemo, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { Item } from "../../../models/ItemModel";
import { useAppDispatch } from "../../../store";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { StyledWrapper } from "../../../test/testUtils";
import { locationMarkerActiveSrc, locationMarkerSrc } from "./Assets";
import ItemPopup from "./ItemPopup";
import { Container } from "./ItemsMap.styles";
import { ITEMS_SOURCE, LOCATION_MARKER, LOCATION_MARKER_ACTIVE, MARKERS_LAYER, mapToGeoJson } from "./Map.helpers";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface ItemsMapProps {
  items: Item[];
}

const ItemsMap: React.FC<ItemsMapProps> = ({ items }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const geoJson = useMemo(() => {
    const itemsWithCoordinates = items.filter((item) => item.geolocation?.latitude && item.geolocation?.longitude);
    return mapToGeoJson(itemsWithCoordinates);
  }, [items]);

  const updateBounds = () => {
    const bounds = new mapboxgl.LngLatBounds();
    geoJson.forEach((item) => {
      bounds.extend({ lng: item.geometry.coordinates[0], lat: item.geometry.coordinates[1] });
    });
    mapRef.current.fitBounds(bounds, { padding: { top: 100, right: 50, bottom: 50, left: 50 }, center: bounds.getCenter() });
  };

  const updateSource = () => {
    const mapSource = mapRef.current?.getSource(ITEMS_SOURCE);
    if (mapSource) {
      mapSource.setData({
        type: "FeatureCollection",
        features: geoJson,
      });
    }
  };

  const editItem = (id: string) => {
    navigate(`/editItem/${id}`);
  };

  const previewItem = (id: string) => {
    dispatch(itemsActions.setPreview({ itemId: id }));
  };

  useEffect(() => {
    if (mapRef.current) return; // initialize map only once
    const locationMarkerImage = document.createElement("img");
    locationMarkerImage.src = locationMarkerSrc;

    const locationMarkerActiveImage = document.createElement("img");
    locationMarkerActiveImage.src = locationMarkerActiveSrc;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/guarding-map-feature/cliit6t6q00au01qv7jg1hhyz",
      zoom: 6,
      projection: "mercator",
    });

    mapRef.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );

    mapRef.current.on("load", () => {
      if (!mapRef.current?.getSource(ITEMS_SOURCE)) {
        mapRef.current?.addImage(LOCATION_MARKER, locationMarkerImage);
        mapRef.current?.addImage(LOCATION_MARKER_ACTIVE, locationMarkerActiveImage);

        if (geoJson.length) {
          mapRef.current?.addSource(ITEMS_SOURCE, {
            generateId: true,
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: geoJson,
            },
          });

          mapRef.current?.addLayer({
            id: MARKERS_LAYER,
            type: "symbol",
            source: ITEMS_SOURCE,
            layout: {
              "icon-image": LOCATION_MARKER,
              "icon-allow-overlap": true,
            },
          });
        }
      }
    });

    let hoveredId = null;

    mapRef.current.on("mouseenter", MARKERS_LAYER, (e) => {
      mapRef.current.getCanvas().style.cursor = "pointer";

      if (e.features.length > 0) {
        if (hoveredId !== null) {
          mapRef.current.setLayoutProperty(MARKERS_LAYER, "icon-image", [
            "match",
            ["string", ["get", "id"]],
            hoveredId,
            LOCATION_MARKER_ACTIVE,
            LOCATION_MARKER,
          ]);
        }
        hoveredId = e.features[0].properties.id;
        mapRef.current.setLayoutProperty(MARKERS_LAYER, "icon-image", ["match", ["string", ["get", "id"]], hoveredId, LOCATION_MARKER_ACTIVE, LOCATION_MARKER]);
      }
    });

    mapRef.current.on("mouseleave", MARKERS_LAYER, () => {
      if (hoveredId !== null) {
        mapRef.current.setLayoutProperty(MARKERS_LAYER, "icon-image", LOCATION_MARKER);
      }
      hoveredId = null;
      mapRef.current.getCanvas().style.cursor = "";
    });

    mapRef.current.on("click", MARKERS_LAYER, (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const item = e.features[0].properties as Item;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popup = new mapboxgl.Popup().setLngLat(coordinates).setHTML("");
      popup.addTo(mapRef.current);

      const popupContent = createRoot(document.querySelector(".mapboxgl-popup-content")); // createRoot(container!) if you use TypeScript
      popupContent.render(<ItemPopup item={item} onEdit={() => editItem(item.id)} onPreview={() => previewItem(item.id)} onClose={() => popup.remove()} />, {
        wrapper: StyledWrapper,
      });
    });

    return () => {
      mapRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (geoJson.length) {
      updateSource();
      updateBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJson]);

  return <Container ref={mapContainerRef} />;
};

export default ItemsMap;
