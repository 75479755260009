import { Stack, Text } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Report } from "../../../models/ReportModel";
import { ShareComponent } from "../../Common/ShareComponent";
import { Content, TopSection, Wrapper } from "./PreviewReport.styles";
import { WrappedPreview } from "./WrappedPreview";

interface PreviewReportProps {
  report: Report;
  isLoading: boolean;
}

export const PreviewReport: FC<PreviewReportProps> = ({ report, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Stack justifyContent="flex-end" mr="XS" mb="XXS">
        <Text uppercase micro color="secondary">
          {t("Reports.previewTitle")}
        </Text>
      </Stack>
      <Content>
        <TopSection>
          <TopSection>{report?.report_id && <ShareComponent id={report?.report_id} type="report" />}</TopSection>
        </TopSection>
        <WrappedPreview isLoading={isLoading} report={report} />
      </Content>
    </Wrapper>
  );
};
