import { Badge, Button, Icon, LoadingIndicator, Stack, Text } from "@secuis/ccp-react-components";
import { format } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocal } from "../../../constants/generalSettings";
import { useUserData } from "../../../hooks/useUserData";
import { REPORT_STATUS, Report } from "../../../models/ReportModel";
import { useAppSelector } from "../../../store";
import { selectReports } from "../../../store/reports/ReportsSelectors";
import { useUser } from "../../../store/user/UserHooks";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { getReport } from "../../../utilities/userUtils";
import { getReportColor } from "../../../utilities/utils";
import { ApproverLoadingWrapper } from "../../Settings/styled";
import { PreviewModalForReportList } from "../Preview/PreviewModalForReportList";
import AttachmentDownloadButton from "./ReportActions/AttachmentDownloadButton";
import { FlaggedItem } from "./ReportActions/FlaggedItem";
import { SeenItem } from "./ReportActions/SeenItem";
import { ListItemWrapped, TagsWrapper, Wrapper } from "./ReportElemForCreators.styles";

interface ReportElemForCreatorsProps {
  report: Report;
  selectedReportId?: string;
  setSelectedReportId: (reportId: string) => void;
}

export const ReportElemForCreators: FC<ReportElemForCreatorsProps> = ({ report, selectedReportId, setSelectedReportId }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { userToken } = useUserData();
  const lang = useAppSelector(selectLanguage);
  const { categorizedDates: dates } = useSelector(selectReports);
  const { userName: authorName, isLoading: isLoadingAuthor } = useUser(report?.author);
  const { userName: approverName, isLoading: isLoadingApprover } = useUser(report?.approval_user);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const date = new Date(report.created);
  const scheduleDate = new Date(report.schedule);

  const navigateToEdit = () => {
    navigate({
      pathname: "/createReport",
      search: new URLSearchParams({ type: report.report_type, id: report.report_id }).toString(),
    });
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const report = await getReport(userToken, selectedReportId, lang);
        setSelectedReport(report);
      } catch (e) {
        console.log(e); // TODO: either error toast or error middleware
      }
    };
    if (showPreview && selectedReportId) {
      fetchReport();
    }
  }, [showPreview, selectedReportId, lang, userToken]);

  return (
    <>
      {dates[report.report_id] && (
        <Stack justifyContent="flex-end" mr="XS" data-testid="dateTag">
          <Text data-testid={`ReportDateSeparator-${dates[report.report_id]}`} micro uppercase color="secondary">
            {t(dates[report.report_id])}
          </Text>
        </Stack>
      )}
      <Wrapper data-testid="ReportItemCreator" onClick={() => setSelectedReportId(report.report_id)} selected={selectedReportId === report.report_id}>
        <SeenItem report={report} selected={selectedReportId === report.report_id} />
        <Stack direction="column">
          <Text small color="secondary" uppercase>
            {t("Reports.version")}
          </Text>
          {report.title}
        </Stack>
        <Stack direction="column">
          <Text small color="secondary" uppercase>
            {t("Reports.author")}
          </Text>
          <ListItemWrapped data-testid="ReportAuthor">
            {isLoadingAuthor ? (
              <ApproverLoadingWrapper>
                <LoadingIndicator size="S" />
              </ApproverLoadingWrapper>
            ) : (
              authorName
            )}
          </ListItemWrapped>
        </Stack>
        <Stack direction="column">
          <Text small color="secondary" uppercase>
            {t("Reports.created")}
          </Text>
          <Text small>
            {date.toLocaleString(i18n.language, { weekday: "short" }) + " "} {format(date, "d MMM", { locale: getLocal(i18n.language) })}
          </Text>
        </Stack>
        <Stack direction="column">
          <Text small color="secondary" uppercase>
            {t("Reports.approver")}
          </Text>
          <ListItemWrapped data-testid="ReportApprover">
            {isLoadingApprover ? (
              <ApproverLoadingWrapper>
                <LoadingIndicator size="S" />
              </ApproverLoadingWrapper>
            ) : (
              approverName
            )}
          </ListItemWrapped>
        </Stack>
        {report.attachment_ids.length > 0 ? (
          <Stack justifyContent="flex-start" alignItems="center">
            <AttachmentDownloadButton userToken={userToken} report={report} />
          </Stack>
        ) : (
          <div />
        )}
        <Stack direction="column">
          <Text small color="secondary" uppercase>
            {t("Reports.status")}
          </Text>
          <Stack direction="column">
            <Stack alignItems="center">
              <Icon color={getReportColor(report.status)} size="M" variant="StatusNew" />
              <Text>{t(`Report.status.${report.status}`)}</Text>
            </Stack>

            {scheduleDate && (report.status === REPORT_STATUS.DELIVERED || report.status === REPORT_STATUS.PUBLISHED) && (
              <Stack gap="XS">
                <Text small>
                  {scheduleDate.toLocaleString(i18n.language, { weekday: "short" }) + " "} {format(scheduleDate, "d MMM", { locale: getLocal(i18n.language) })}
                </Text>
                <Text small color="secondary">
                  {format(scheduleDate, "HH:mm", { locale: getLocal(i18n.language) })}
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
        <div>
          {report.status === REPORT_STATUS.PUBLISHED || report.status === REPORT_STATUS.DRAFT ? (
            <Button mode="contained" color="accent" onClick={navigateToEdit}>
              {t("Reports.edit")}
            </Button>
          ) : (
            <Button data-testid="ReportPreviewButton" mode="contained" color="accent" onClick={() => setShowPreview(true)}>
              {t("Reports.preview.name")}
            </Button>
          )}
        </div>
        <FlaggedItem report={report} />
        <TagsWrapper>
          {[...new Set(report.tags)].map((elem) => (
            <Badge key={`${report.report_id}-${elem}`} label={getTranslatedTag(elem)} />
          ))}
        </TagsWrapper>
      </Wrapper>
      <PreviewModalForReportList showPreview={showPreview} setShowPreview={setShowPreview} report={selectedReport} />
    </>
  );
};
