import { Box, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const DateWrapper = styled(Stack).attrs({
  direction: "column",
  alignItems: "flex-end",
})`
  @media (max-width: ${Breakpoints.S}) {
    text-align: end;
  }
`;

export const TagsWrapper = styled(Stack).attrs({
  alignItems: "center",
  flexWrap: "wrap",
  gap: "XS",
})`
  margin-left: ${Spacing.M}px;

  @media (max-width: ${Breakpoints.S}) {
    margin-left: ${Spacing.XXS}px;
  }
`;

export const TitleWrapper = styled(Stack)`
  margin-left: ${Spacing.M}px;

  @media (max-width: ${Breakpoints.S}) {
    margin-left: ${Spacing.XXS}px;
  }
`;

interface WrapperProps {
  selected: boolean;
}

export const Wrapper = styled(Box).attrs({
  p: "S",
  mr: "XS",
})<WrapperProps>`
  background-color: ${Palette.Navy800};
  border-radius: 4px;

  display: grid;
  grid-template-columns: 40px 8fr 2fr;
  grid-template-rows: auto;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${Palette.Navy750};
    }
  }

  ${(props) =>
    props.selected &&
    css`
      border: ${Palette.Purple300} 3px solid;
      background-color: ${Palette.Navy750};
    `};
`;
