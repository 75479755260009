import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, Reports } from "../../models/ReportModel";
import { categorizeDates } from "../../utilities/dateUtils";
import { userApi } from "../user/UserApi";
import { reportsApi } from "./ReportsApi";

export type ReportState = {
  data: Reports;
  status: FetchStatus;
  page: number;
  showMore: boolean;
};

export const initialState: ReportState = {
  data: {
    filteredReports: [],
    categorizedDates: {},
  },
  page: 1,
  showMore: false,
  status: "idle",
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(reportsApi.endpoints.getReports.matchPending, (state, action) => {
        state.status = action.meta.arg.originalArgs.page === 1 ? action.meta.requestStatus : state.status;
      })
      .addMatcher(reportsApi.endpoints.getReports.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(reportsApi.endpoints.getReports.matchFulfilled, (state, action) => {
        const { data, meta } = action.payload;

        const fetchReports = action.meta.arg.originalArgs.page === 1 ? data : [...state.data.filteredReports, ...data];

        state.data = {
          filteredReports: fetchReports as any,
          categorizedDates: categorizeDates(fetchReports, "schedule"),
        };
        state.showMore = data.length > 0;
        state.status = action.meta.requestStatus;
        state.page = meta.page || state.page;
      })
      .addMatcher(userApi.endpoints.setInteractions.matchFulfilled, (state, action) => {
        if (action.payload.entity_type === "report") {
          state.data.filteredReports = state.data.filteredReports.map((r) =>
            action.payload.entity_id === r.report_id
              ? { ...r, user_interaction: { ...r.user_interaction, viewed: action.payload.viewed, flagged: action.payload.flagged } }
              : r
          );
        }
      })
      .addMatcher(reportsApi.endpoints.deleteReport.matchPending, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(reportsApi.endpoints.deleteReport.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
      })
      .addMatcher(reportsApi.endpoints.deleteReport.matchFulfilled, (state, action) => {
        state.data.filteredReports = state.data.filteredReports.filter(({ report_id }) => report_id !== action.meta.arg.originalArgs.reportId);
        state.status = action.meta.requestStatus;
      });
  },
});

export const reportsActions = reportsSlice.actions;
